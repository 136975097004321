import React from 'react';
import { Grid, Typography } from '@mui/material';

export default function Navbar() {

    return (
        <Grid 
            container
            xs={12}
            justifyContent='space-between'
            sx={{marginTop: 5, marginBottom: {xs: 10, md: 15}, marginLeft: 4}}
        >
            <Typography variant='h5' fontWeight='bold'>
                ZAHARA
            </Typography>

        </Grid>
    )
}

