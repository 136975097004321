import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import CssBaseline from "@mui/material/CssBaseline";
import { createMuiTheme } from '@mui/material';
import { ThemeProvider } from '@emotion/react';

const theme = createMuiTheme({
  typography: {
    fontFamily: [
      'Montserrat'
    ].join(',')
  }
})



const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <ThemeProvider theme={theme}>
    <React.StrictMode>
      <CssBaseline>
        <App />
      </CssBaseline>
    </React.StrictMode>
  </ThemeProvider>
);

