import { Grid, Typography } from '@mui/material';
import React from 'react';

export default function Footer() {
    return (
        <Grid
            container
            xs={12}
            justifyContent='space-evenly'
            sx={{
                backgroundColor: '#2D3047',
                height: '100%',
                paddingTop: 5, 
                paddingBottom: 5,
            }}    
        >
            <Grid item>
                <Typography variant='h5' fontWeight='bold' color='white' sx={{textAlign: {xs: 'center', md:'left'}}}>
                    ZAHARA
                </Typography>
                <Typography color='white' mt={2}>
                    Helping you Build Better.<br/>So that you can Be Better.
                </Typography>
            </Grid>

            <Grid item>
                <Typography fontSize={14} color='grey' sx={{marginTop: {xs: 5, md: 0}, textAlign: {xs: 'center', md:'left'}}}>
                    CONTACT US
                </Typography>
                <Typography color='white' sx={{marginBottom: {xs: 5, md: 0}}}>
                    antonio@zahara.consulting
                </Typography>
            </Grid>
        </Grid>
    )
}