import React from 'react';
import './App.css';
import emailjs from '@emailjs/browser';
import TagManager from 'react-gtm-module';
import { Box, Button, Divider, FormControl, Grid, IconButton, Modal, OutlinedInput, Typography } from '@mui/material';
import Navbar from './Components/Navbar';
import Footer from './Components/Footer';
import CloseIcon from '@mui/icons-material/Close';

const modalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  backgroundImage: 'url(images/form-background.png)',
  backgroundSize: 'cover',
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
}

// const tagManagerArgs = {
//   gtmId: process.env.REACT_APP_GTM_CONTAINER_ID ?? '',
//   events: {
//     contactFormOpened: 'contactForm'
//   }
// }

export default function App() {

  const [open, setOpen] = React.useState(false);
  const [disabled, setDisabled] = React.useState(false);
  const handleOpen = () => {
    // TagManager.initialize(tagManagerArgs)
    TagManager.dataLayer({
      dataLayer: {
        event: 'contactFormOpened'
      }
    })
    setOpen(true);
  }
  const handleClose = () => setOpen(false);
  const form = React.useRef<HTMLFormElement>(null);
  const handleSubmit = (e:any) => {
    setDisabled(true);
    e.preventDefault();

    emailjs.sendForm(
      process.env.REACT_APP_EMAILJS_SERVICE_ID ?? '', 
      process.env.REACT_APP_EMAILJS_TEMPLATE_ID ?? '', 
      form.current ?? '', 
      process.env.REACT_APP_EMAILJS_PUBLIC_KEY ?? ''
    )
      .then((result) => {
        // console.log(result);
        setDisabled(false);
        handleClose();
      }, (error) => {
        setDisabled(false);
        console.log(error)
      });
  };

  return (
    <Grid 
      container 
      xs={12} 
      justifyContent='center' 
      maxWidth='100vw'
      sx={{ 
        overflowWrap: 'break-word',
        overflowX: 'hidden',
        }}
      >
      <Navbar />
      
      <Grid 
        item 
        container 
        height={'100%'} 
        xs={12} 
        md={8} 
        sx={{
          justifyContent: {xs: 'center', md: 'space-evenly'}, 
          background: 'rgba(167,153,183,.8)', 
          boxShadow: '-40rem -20rem rgba(45,48,71,.5), 40rem 20rem rgba(224,202,60,.6)', 
          borderRadius: 100,
          padding: 5
          }} 
        >

        <Grid item container xs={10} md={4} >
          <Typography color='grey' fontWeight={600}>
            Build Better. Be Better.
          </Typography>
          <Typography variant='h3' fontWeight='bold'>
            Software Solutions<br/>
            tailored to your<br/>
            business
          </Typography>
          <Button onClick={handleOpen} variant='contained' sx={{height: 50, backgroundColor: '#048A81'}}>
            Contact Us Today
          </Button>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="contact-us-form-modal"
            aria-describedby="popup-modal-for-contact-form"
          >
            <Grid item container xs={12} sx={modalStyle}>
              <Grid item container xs={12} justifyContent='space-between'>
                <Typography p={2} variant='h4' fontWeight={600}>
                  Contact Us
                </Typography>
                <IconButton onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </Grid>
              <form ref={form} onSubmit={handleSubmit}>
                <Grid item container xs={12} justifyContent='center'>
                  <FormControl>
                    <OutlinedInput type='text' name='from_name' required placeholder='Name' sx={{backgroundColor: 'white', margin: 2}} />
                  </FormControl>
                  <FormControl>
                    <OutlinedInput type='email' name='from_email' required placeholder='Email' sx={{backgroundColor: 'white', margin: 2}} />
                  </FormControl>
                  <FormControl>
                    <OutlinedInput type='phone' name='from_phone' required placeholder='Phone Number' sx={{backgroundColor: 'white', margin: 2}} />
                  </FormControl>
                  <FormControl>
                    <OutlinedInput type='text' name='from_organization' placeholder='Organization' sx={{backgroundColor: 'white', margin: 2}} />
                  </FormControl>
                  <FormControl>
                    <OutlinedInput type='text' name='message' required  multiline minRows={4} placeholder='How can we help?' sx={{backgroundColor: 'white', margin: 2}} />
                  </FormControl>
                  <FormControl>
                    <Button disabled={disabled} variant='contained' type='submit' sx={{marginLeft: 1, backgroundColor: '#048A81'}}>
                      Submit
                    </Button>
                  </FormControl>
                </Grid>
              </form>
            </Grid>
          </Modal>
        </Grid>

        <Grid item container xs={10} md={4}>
          <Box 
            component='img'
            sx={{
              width: {xs: 350, md: 600}
            }}
            src='images/header-image.png'
          />
        </Grid>
         
      </Grid>

      <Grid item container xs={12} mt={15} sx={{justifyContent: {xs: 'center', md: 'space-evenly'}}}>
        <Grid item xs={10} md={2} sx={{marginBottom: {xs: 5, md: 0}}}>
          <Typography fontWeight='bold' color='grey' textAlign='center'>
            CONSULT
          </Typography>
          <Typography textAlign='center' fontWeight={600}>
            We work closely with you to come to an agreed plan on the goals that you want to achieve
          </Typography>
        </Grid>

        <Grid item xs={10} md={2} sx={{marginBottom: {xs: 5, md: 0}}}>
          <Typography fontWeight='bold' color='grey' textAlign='center'>
            PLAN
          </Typography>
          <Typography textAlign='center' fontWeight={600}>
            We spend every moment laying out a plan of action to make sure our consultation delivers extraordinary results
          </Typography>
        </Grid>

        <Grid item xs={10} md={2} sx={{marginBottom: {xs: 5, md: 0}}}>
          <Typography fontWeight='bold' color='grey' textAlign='center'>
            DEVELOP
          </Typography>
          <Typography textAlign='center' fontWeight={600}>
            Putting it all into action until a finished product that 100% fits the plan
          </Typography>
        </Grid>

        <Grid item xs={10} md={2} sx={{marginBottom: {xs: 5, md: 0}}}>
          <Typography fontWeight='bold' color='grey' textAlign='center'>
            SUPPORT
          </Typography>
          <Typography textAlign='center' fontWeight={600}>
            Ongoing support to enable your new product to thrive knowing that we are standing behind you
          </Typography>
        </Grid>

      </Grid>



      <Divider light />

      <Grid item container xs={12} md={12} sx={{justifyContent: {xs: 'center', md: 'space-evenly'}, marginTop: {xs:5, md: 15}, padding: 2}}>
      
            <Grid 
              md={3} 
              sx={{
                background: 'rgba(147,183,190,.8)', 
                borderRadius: {xs:0, md:100}, 
                boxShadow: '20rem 20rem rgba(45,48,71,.5)',
                padding: 2,
                }}
              >
              <Typography variant='h5'fontWeight='bold' color='grey' mb={4}>
                Experience
              </Typography>
              <Typography fontWeight={600}>
                From the beginning we have learned  and grew from experience and new emerging technologies. Our founder has experience in software consulting, federal government software building, and transforming/implementing non-profit QA efforts!
              </Typography>
            </Grid>

            <Grid md={4}>
              <Box 
                component='img'
                sx={{
                  width: {xs: 350, md: 600}
                }}
                src='images/experience-image.png'
              />
            </Grid>


            <Grid md={3} alignSelf='center'>
              <Typography variant='h5'fontWeight='bold' color='grey' mb={4}>
              Pure Trust
              </Typography>
              <Typography fontWeight={600} sx={{}}>
                When you work with us, know that you will<br/> be getting the absolute best service and support that is possible. We are interested in making relationships that last and build from the products we build.
              </Typography>
            </Grid>

      </Grid>

      <Grid item container xs={12} md={8} sx={{justifyContent: {xs: 'center', md: 'space-between'}, marginTop: {xs:5, md: 15}, padding: 2}}>
        <Grid md={4}>
          <Box 
            component='img'
            sx={{
              width: {xs: 250, md: 500},
            }}
            src='images/match-image.png'
          />
        </Grid>

        <Grid md={4} alignSelf='center'>
          <Typography variant='h5' fontWeight='bold' mb={4} color="grey">
            The Perfect Match
          </Typography>
          <Typography mb={4} fontWeight={600}>
          Let's talk today! With our free consultation, we at Zahara want to assist you in whatever your software needs may be. Reach out so that we can start a perfect match today.
          </Typography>
          <Button onClick={handleOpen} variant='contained' sx={{backgroundColor: '#048A81'}}>
            Let's Talk
          </Button>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="contact-us-form-modal"
            aria-describedby="popup-modal-for-contact-form"
          >
            <Grid item container xs={12} sx={modalStyle}>
              <Grid item container xs={12} justifyContent='space-between'>
                <Typography p={2} variant='h4' fontWeight={600}>
                  Contact Us
                </Typography>
                <IconButton disabled={disabled} onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </Grid>
              <form ref={form} onSubmit={handleSubmit}>
                <Grid item container xs={12} justifyContent='center'>
                  <FormControl>
                    <OutlinedInput type='text' name='from_name' required placeholder='Name' sx={{backgroundColor: 'white', margin: 2}} />
                  </FormControl>
                  <FormControl>
                    <OutlinedInput type='email' name='from_email' required placeholder='Email' sx={{backgroundColor: 'white', margin: 2}} />
                  </FormControl>
                  <FormControl>
                    <OutlinedInput type='phone' name='from_phone' required placeholder='Phone Number' sx={{backgroundColor: 'white', margin: 2}} />
                  </FormControl>
                  <FormControl>
                    <OutlinedInput type='text' name='from_organization' placeholder='Organization' sx={{backgroundColor: 'white', margin: 2}} />
                  </FormControl>
                  <FormControl>
                    <OutlinedInput type='text' name='message' required  multiline minRows={4} placeholder='How can we help?' sx={{backgroundColor: 'white', margin: 2}} />
                  </FormControl>
                  <FormControl>
                    <Button variant='contained' type='submit' sx={{marginLeft: 1, backgroundColor: '#048A81'}}>
                      Submit
                    </Button>
                  </FormControl>
                </Grid>
              </form>
            </Grid>
          </Modal>
        </Grid>

      </Grid>

      <Grid 
        item 
        container 
        xs={12} 
        justifyContent='center' 
        mt={10} 
        mb={10}
        sx={{
          boxShadow: {xs: '5rem 5rem rgba(4,138,129,.5), -6rem -3rem rgba(224,202,60,.6)',md: '60rem -16rem rgba(224,202,60,.6), -60rem 16rem rgba(4,138,129,.5)'},
          borderRadius: {xs: 5, md: 100},
        }}
      >
        <Grid xs={12} textAlign='center'>
          <Typography variant='h4' fontWeight='bold' mb={4} color="grey">
            Our Work
          </Typography>
        </Grid>

        <Grid xs={12} textAlign='center'>
          <Box 
              component='img'
              sx={{
                width: {xs: 400, sm: 700, lg:800},
              }}
              src='images/schext-client.png'
          />
          <Typography fontWeight={600}>
            Changing the way scheduling appointments for freelancers work. We<br/> had the opportunity to create Schext from nothing to something extraordinary!
          </Typography>
        </Grid>
      </Grid>

    <Footer />
    </Grid>
  );
}
